import * as userdetail from './userDetail';
import * as warehouse from './warehouse';
import * as shipments from './shipment';
import * as pocs from './poc';
import * as pickup from './pickup';
import * as about from './about';
import * as dashboard from './dashboard';
import * as billing from './billing';
import * as finalinvoicing from './finalinvoicing';
import * as commonApi from './common';
import * as rating from './rating';
import * as dpAccount from './dpAccount';
import * as channels from './channels';
import * as serviceabilityCheck from './pincodeServiceability';
import * as rates from './rates';
import * as customer from './customers';
import * as autoAssignCarrier from './autoAssignCarrier';
import * as zone from './zone';

// ------------- User -------------- //
const getConsumerToken = userdetail.getConsumerToken;
const getClientDetails = userdetail.getClientDetails;
const getClientList = userdetail.getClientList;
const getUsersByRole = userdetail.getUsersByRole;
const revokeConsumerTokenOAuth = userdetail.revokeConsumerTokenOAuth;
const userKYC = userdetail.userKYC;
const profile = userdetail.profile;
const getKYCList = userdetail.getKYCList;
const addClient = userdetail.addClient;
const userAgreement = userdetail.userAgreement;
const optSecureShipment = userdetail.optSecureShipment;
const UserBankDetails = userdetail.UserBankDetails;
const getUserCarriers = userdetail.getUserCarriers;
const createDisableCarrier = userdetail.createDisableCarrier;
const deleteDisableCarrier = userdetail.deleteDisableCarrier;
const generateApiToken = userdetail.generateApiToken;
const getApiToken = userdetail.getApiToken;
const clientSecureShipmentDetails = userdetail.clientSecureShipmentDetails;
const getClientSecureShipmentDetails =
	userdetail.getClientSecureShipmentDetails;
const getAllClientSecureShipmentDetails =
	userdetail.getAllClientSecureShipmentDetails;
const getClientUsers = userdetail.getClientUsers;
const getCouponApi = userdetail.getCouponApi;
const validateCouponApi = userdetail.validateCouponApi;

// ------------- Warehouse -------------- //
const getWarehouseList = warehouse.getWarehouseList;
const addWarehouse = warehouse.addWarehouseAsync;
const getStates = warehouse.getStates;
const editWarehouse = warehouse.editWarehouse;
const deleteWarehouse = warehouse.deleteWarehouse;
const getMostUsedWarehouseList = warehouse.getMostUsedWarehouseList;
const getAddressByPincode = warehouse.getAddressByPincode;

// ------------- Pickup -------------- //
const getPickupList = pickup.getPickupList;
const createPickupRequestAsync = pickup.createPickupRequestAsync;
const getPickupAvailability = pickup.getPickupAvailability;
const editPickupRequest = pickup.editPickupRequest;
const deletePickup = pickup.deletePickup;
const getUpdatedPickup = pickup.getUpdatedPickup;
const pickupReportDownload = pickup.pickupReportDownload;

// ------------- POC -------------- //
const getPocList = pocs.getPocList;
const deletePoc = pocs.deletePoc;
const updatePOC = pocs.updatePOC;
const addPocAsync = pocs.addPocAsync;

// ------------- DP Account -----------//
const addDPAccount = dpAccount.addDPAccount;
const getDPFields = dpAccount.getDPFields;
const updateDPAccount = dpAccount.updateDPAccount;
const getDPAccountList = dpAccount.getDPAccountList;

// ------------- Shopify -----------//
const addShopify = channels.addShopify;
const getShopifyAccountList = channels.getShopifyAccountList;
const deleteShopify = channels.deleteShopify;

// ------------- About -------------- //
const getResources = about.getResources;
const getFreshDeskTicket = about.getFreshDeskTicket;
const getFreshDeskTicketView = about.getFreshDeskTicketView;
const putFreshDeskCreateTicket = about.putFreshDeskCreateTicket;
const putFreshDeskUpdateTicket = about.putFreshDeskUpdateTicket;
// ------------- Dashboard -------------- //
const getPickupStats = dashboard.getPickupStats;
const getClientStats = dashboard.getClientStats;
const getSlaReports = dashboard.getSlaReports;
const getTargetReports = dashboard.getTargetReports;

// ------------- Billing -------------- //
const {
	getZohoInvoices,
	getZohoPayments,
	getZohoCreditNotes,
	getUnbilledShipments,
	getZohoDues,
	createOrderId,
	createRazorPayOrder,
	getWalletHistory,
	downloadPassbook,
	getInvoiceWorking,
	getShiprocketWalletBalance,
	debitAmountFromSR,
	getGrossMargin,
	downloadPOD,
	downloadCreditNotePDF,
} = billing;

// ------------- Final Invoicing -------------- //
const getFinalInvoicingData = finalinvoicing.getFinalInvoicingData;
const editCalculatedWorking = finalinvoicing.editCalculatedWorking;
const generateInvoices = finalinvoicing.generateInvoices;
const getZoneList = finalinvoicing.getZoneList;
const getClientWithShipmentsToBeInvoiced =
	finalinvoicing.getClientWithShipmentsToBeInvoiced;
const approveBulkInvoice = finalinvoicing.approveBulkInvoice;
// ------------- Shipments -------------- //
const getDeliveryPartners = shipments.getDeliveryPartners;
const getDPModeLists = shipments.getDPModeLists;
const getShipments = shipments.getShipments;
const getShipmentsFetch = shipments.getShipmentsFetch;
const getStuckShipments = shipments.getStuckShipments;
const addShipment = shipments.addShipment;
const editShipment = shipments.editShipment;
const deleteShipment = shipments.deleteShipment;
const getDeliveryPartnerWarehouses = shipments.getDeliveryPartnerWarehouses;
const getUpdatedShipments = shipments.getUpdatedShipments;
const misDownload = shipments.misDownload;
const stuckShipmentDownload = shipments.stuckShipmentDownload;
const getShipmentTrackDetails = shipments.getShipmentTrackDetails;
const calculateShipment = shipments.calculateShipment;
const getShipmentCounts = shipments.getShipmentCounts;
const getPickuptime = shipments.getPickuptime;
const getShipmentAmountCharged = shipments.getShipmentAmountCharged;
const getToPayShipments = shipments.toPayShipmentList;
const getWeightDiscrepancies = shipments.weightDiscrepancieList;
const updateWeightDiscrepancies = shipments.raiseDispute;
const retryShipment = shipments.retryShipment;
const downloadRemittance = shipments.downloadRemittance;
const getNdrShipments = shipments.getNdrShipments;
const editNDRShipment = shipments.editNDRShipment;

//----------------Abd request ----------------//
const getAbdRequest = shipments.getAbdRequest;
const postUploadAbdRequest = shipments.postUploadAbdRequest;
const postDownloadAbdRequest = shipments.postDownloadAbdRequest;
const putAbdRequestApi = shipments.putAbdRequestApi;
const postPoMultipleUpload = shipments.postPoMultipleUpload;
// ------------- Orders - Shipment -------------- //

const getOrders = shipments.getOrders;
const createOrder = shipments.createOrder;
const editOrder = shipments.editOrder;
const bulkUploadOrders = shipments.bulkUploadOrders;
const bulkUploadWarehouse = shipments.bulkUploadWarehouse;
const mapPickupShipment = shipments.mapPickupShipment;
const orderShipmentAssociation = shipments.orderShipmentAssociation;
const editOrderAssociationShipment = shipments.editOrderAssociationShipment;
const editAppointmentShipment = shipments.editAppointmentShipment;
const deleteOrder = shipments.deleteOrder;
const getUpdatedOrders = shipments.getUpdatedOrders;
const getPricingPlanPopup = shipments.getPricingPlanPopup;
const getPricingPlan = shipments.getPricingPlan;
const getPricingPLanSaving = shipments.getPricingPLanSaving;
// ------------- Orders - Pickup -------------- //

const orderPickupAssociation = pickup.orderPickupAssociation;

/* ------------- Common APIs ------------- */
const getDeliveryPartnerCftFactors = commonApi.getDeliveryPartnerCftFactors;
const generateSignedUrl = commonApi.generateSignedUrl;
const uploadFiletoS3 = commonApi.uploadFiletoS3;
const updateUserTnCStatus = commonApi.updateUserTnCStatus;
const generateLogger = commonApi.generateLogger;
const updateUser = commonApi.updateUser;
/* ------------- Rating Service APIs ------------- */
const getEligibleShipmentsForRating = rating.getEligibleShipmentsForRating;
const addRating = rating.addRating;

/*--------------------pincode serviceability----------------*/
const pincodeServiceability = serviceabilityCheck.pincodeServiceability;
const getPincodeDownload = serviceabilityCheck.getPincodeDownload;
const downloadServiceability = serviceabilityCheck.downloadServiceability;

/*-------------------- rates ----------------*/
const getRates = rates.getRates;
const getVasRates = rates.getVasRates;
const saveRatesForClient = rates.saveRatesForClient;
const getRatesForClient = rates.getRatesForClient;
const { AssignrateChartToClient } = rates;

// ------------- Customer -------------- //
const addCustomer = customer.addCustomer;
const getCustomerList = customer.getCustomerList;
const deleteCustomer = customer.deleteCustomer;

// ------------- Customer -------------- //
const getAutoAssignCarrier = autoAssignCarrier.getAutoAssign;
const createAutoAssignCarrier = autoAssignCarrier.createAutoAssign;
const updateAutoAssignCarrier = autoAssignCarrier.editAutoAssign;
const deleteAutoAssignCarrier = autoAssignCarrier.deleteAutoAssign;
const getAllModeLists = autoAssignCarrier.getAllModeLists;
const saveRule = autoAssignCarrier.saveRule;
const updateRule = autoAssignCarrier.updateRule;
const editRule = autoAssignCarrier.editRule;

// ------------- Zone -------------- //
const zoneEvent = zone.zoneEvent;
const getAllZoneUploadData = zone.getAllZoneUploadData;
//

export {
	// User
	getConsumerToken,
	revokeConsumerTokenOAuth,
	getClientDetails,
	getClientList,
	getUsersByRole,
	userKYC,
	profile,
	getKYCList,
	addClient,
	userAgreement,
	UserBankDetails,
	getUserCarriers,
	createDisableCarrier,
	deleteDisableCarrier,
	clientSecureShipmentDetails,
	getClientSecureShipmentDetails,
	getAllClientSecureShipmentDetails,
	getClientUsers,
	getCouponApi,
	validateCouponApi,
	// Warehouse
	getWarehouseList,
	getAddressByPincode,
	addWarehouse,
	getStates,
	editWarehouse,
	deleteWarehouse,
	getMostUsedWarehouseList,
	// Pickup
	getPickupList,
	createPickupRequestAsync,
	getPickupAvailability,
	editPickupRequest,
	deletePickup,
	getUpdatedPickup,
	pickupReportDownload,
	// POC
	getPocList,
	deletePoc,
	updatePOC,
	addPocAsync,
	// Orders - Shipments
	getOrders,
	createOrder,
	editOrder,
	bulkUploadOrders,
	bulkUploadWarehouse,
	mapPickupShipment,
	orderShipmentAssociation,
	editOrderAssociationShipment,
	editAppointmentShipment,
	deleteOrder,
	getUpdatedOrders,
	// Orders - Pickup
	orderPickupAssociation,
	// DP Account
	addDPAccount,
	updateDPAccount,
	getDPFields,
	getDPAccountList,
	// Shopify
	addShopify,
	getShopifyAccountList,
	deleteShopify,
	// Shipments
	getDeliveryPartners,
	getDPModeLists,
	getShipments,
	getShipmentsFetch,
	addShipment,
	editShipment,
	deleteShipment,
	getDeliveryPartnerWarehouses,
	getUpdatedShipments,
	misDownload,
	stuckShipmentDownload,
	getShipmentTrackDetails,
	calculateShipment,
	getShipmentCounts,
	getStuckShipments,
	getShipmentAmountCharged,
	getToPayShipments,
	getWeightDiscrepancies,
	updateWeightDiscrepancies,
	retryShipment,
	downloadRemittance,
	getNdrShipments,
	editNDRShipment,
	//abd request
	getAbdRequest,
	postUploadAbdRequest,
	postDownloadAbdRequest,
	putAbdRequestApi,
	postPoMultipleUpload,
	// About
	getResources,
	getFreshDeskTicket,
	getFreshDeskTicketView,
	putFreshDeskCreateTicket,
	putFreshDeskUpdateTicket,
	// Dashboard
	getPickupStats,
	getClientStats,
	getSlaReports,
	getTargetReports,
	//Billing
	getZohoInvoices,
	getZohoPayments,
	getZohoCreditNotes,
	getUnbilledShipments,
	getZohoDues,
	createOrderId,
	createRazorPayOrder,
	getWalletHistory,
	downloadPassbook,
	getInvoiceWorking,
	getShiprocketWalletBalance,
	debitAmountFromSR,
	getGrossMargin,
	downloadPOD,
	downloadCreditNotePDF,
	//Final Invoicing
	getFinalInvoicingData,
	editCalculatedWorking,
	generateInvoices,
	getZoneList,
	getClientWithShipmentsToBeInvoiced,
	approveBulkInvoice,
	//Common APIs
	getDeliveryPartnerCftFactors,
	generateSignedUrl,
	uploadFiletoS3,
	updateUserTnCStatus,
	generateLogger,
	updateUser,
	getPricingPlanPopup,
	getPricingPlan,
	getPricingPLanSaving,
	//Rating APIs
	getEligibleShipmentsForRating,
	addRating,
	//pincode serviceability
	pincodeServiceability,
	downloadServiceability,
	//pincode serviceability
	getPincodeDownload,
	getRates,
	getVasRates,
	saveRatesForClient,
	getRatesForClient,
	AssignrateChartToClient,
	// Customer
	addCustomer,
	getCustomerList,
	deleteCustomer,
	getPickuptime,
	optSecureShipment,
	//Auto Assign Carrier
	getAutoAssignCarrier,
	createAutoAssignCarrier,
	updateAutoAssignCarrier,
	deleteAutoAssignCarrier,
	getAllModeLists,
	saveRule,
	updateRule,
	editRule,
	generateApiToken,
	getApiToken,
	//Zone
	zoneEvent,
	getAllZoneUploadData,
};
